<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('sidebar.terms')}}</h4>
                <breadcrumb >
                    <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}"  class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>



        <div class="row">

            <div class="col-12">
                <card class="mt-2" v-loading="screenLoading"
                      element-loading-lock="true"
                      :element-loading-text="screenText"
                      element-loading-spinner="el-icon-loading">

                    <div>
                        <div class="col-sm-12">
                            <div class="box-info-terms">


                                <div class="box-item top">
                                    <span class="fa fa-file-text icon color-primary"></span>
                                    <label>{{ this.$t('property.terms.privacy')}}</label>
                                    <div class="box-actions">

                                        <a v-if="writting_permission" v-tooltip.top-center="$t('form.edit')" class="color-blue-var btn-warning btn-simple btn-link ml-1"
                                            @click="handleEdit(property._id, 'editPrivacis')"><i
                                                class="fa fa-pencil"></i></a>
                                    </div>

                                </div>
                                <div class="box-item top">
                                    <span class="fa fa-file-text icon color-primary"></span>
                                    <label>{{ this.$t('property.terms.terms')}}</label>
                                    <div class="box-actions">
                                        <a v-if="writting_permission" v-tooltip.top-center="$t('form.edit')" class="color-blue-var  btn-warning btn-simple btn-link ml-1"
                                           @click="handleEdit(property._id, 'editTerms')"><i
                                                class="fa fa-pencil"></i></a>
                                    </div>
                                </div>
                                <div class="box-item top">
                                    <span class="fa fa-file-text icon color-primary"></span>
                                    <label>{{ this.$t('property.terms.politics')}}</label>
                                    <div class="box-actions">
                                        <a v-if="writting_permission" v-tooltip.top-center="$t('form.edit')" class="color-blue-var btn-warning btn-simple btn-link ml-1"
                                           @click="handleEdit(property._id, 'editPolitics')"><i
                                                class="fa fa-pencil"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
    import {Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    //import users from 'src/pages/Dashboard/Tables/users'
    import PropertyService from '../../../js/services/PropertyService';
    import { vueTopprogress } from 'vue-top-progress';
    const propertyService = new PropertyService();
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    import EventBus from "../../../js/helpers/EventBus";

    import { mapGetters } from "vuex";


    export default {
        computed: {
          ...mapGetters(["currentUser", "selected_property","permissions","writting_permission"]),
        },
        components: {
            Breadcrumb,
            LSwitch,
            BreadcrumbItem,
            vueTopprogress
        },
        data() {
            return {
                screenLoading: false,
                screenText: '',
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('sidebar.terms'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page:{
                    property_id: {},
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },]
                },
                property: {
                    _id: '',
                    name: '',
                    terms: '',
                    politics: '',
                    status: false,
                },
                section: 'PROPERTY',

            }
        },
        created(){
            this.chargerItems();
            this.$store.dispatch('CHANGE_SECTION',this.section);
          
        },
        mounted() {

            this.changeProperty()
            //this.fuseSearch = new Fuse(this.tableData, {keys: ['name']})
            //this.changeProperty()
            /*this.$refs.topProgress.start();
            setTimeout(() => {

                this.$refs.topProgress.done();
            }, 200);*/
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='indexTerms'){
                        this.chargerItems(property_id);
                    }
                })
            },
            chargerItems(){
                this.initLoading();
                let property_id = this.selected_property._id;
                this.info_page.property_id = property_id;
                this.property._id = property_id;

               /* propertyService.getPropertyId(property_id).then(response => {
                    let data = response.data.data.property;
                    console.log('data', data);

                    this.property._id = data._id;
                    this.property.name= data.name;
                    this.property.terms= data.phone;
                    this.property.politics= data.street;
                    this.property.status= data.status;
                });*/
                setTimeout(() => {
                    this.closeLoading();
                }, 500);
            },
            handleEdit(id, section) {
                this.$router.push({
                    name: section,
                    params: {
                        id: id
                    }
                })
            },
            updateStatus(id, status, field){
                /*status = (status)?'false':'true';
                //console.log('cambiandop status', id, status);
                contractService.updateStatus(id, status).then(response => {
                    console.log('iresponse',  response);
                });*/

                /*setTimeout(() => {
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'true';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'false';
                    }

                    contractService.updateStatus(id, status).then(response => {

                        let contract = response.data.data.rateplan;
                        //console.log(response.data, 'respuesta')
                        if(contract.status===true){
                            this.items.push(contract)
                        }else{
                            this.items_i.push(contract)
                        }

                        this.info_page.actives =  this.items.length;
                        this.info_page.inactives =  this.items_i.length;
                    });
                }, 800);*/


            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }


        }
    }
</script>
<style lang="scss">

    .box-info-terms{
        border: 0;
        padding: 40px 0;
        display: inline-block;
        .box-item {
            padding: 25px;
            border-left: 2px solid #eeeeee;
            border-bottom: 2px solid #eeeeee;
            border-right: 2px solid #eeeeee;
            overflow: hidden;
            float: left;
            margin: 0;
            width: 100%;
            &:first-child{
                border-top: 2px solid #eeeeee;
            }
        }
        span.icon{
            font-size: 28px;
            color: #000;
            width: 10%;
            display: inline-block;

        }
        .box-actions{
            font-size: 25px;
            color: #000;
            width: 20%;
            display: inline-block;
        }
        label{
            color: #000;
            width: 70%;
            display: inline-block;
            font-size: 18px;
        }
    }
</style>
